import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import NewAgendaItem from "../Components/NewAgendaItem";
import NewActionItem from "../Components/NewActionItem";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { RiSendPlaneFill } from "react-icons/ri";
import { GiTransform } from "react-icons/gi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faList } from "@fortawesome/free-solid-svg-icons";
import "./MeetingDetails.css";

function MeetingDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [meeting, setMeeting] = useState({});
  const [agendaItems, setAgendaItems] = useState([]);
  const [actionItems, setActionItems] = useState([]);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const { state } = useContext(AuthContext);

  let { mid } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/meetings/${mid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setMeeting(result.data.meeting);
      setUsers(result.data.users);
      setAgendaItems(result.data.meeting.agendaItems);
      setComments(result.data.meeting.comments);
      if (result.data.meeting.actionItems.length > 0) {
        setActionItems(result.data.meeting.actionItems);
      } else {
        let defaultUser = result.data.users[0];
        setActionItems([
          {
            _id: uuidv4(),
            description: "",
            completed: false,
            dueDate: new Date(),
            responsible: defaultUser.id,
            responsibleName: defaultUser.name,
          },
        ]);
      }
      setDisabled(result.data.meeting.creator !== state.userId);
      setIsLoading(false);
    }
    fetch();
  }, [state.token, mid, state.userId]);

  const handleChangeInputAgenda = (e, id) => {
    const newAgendaItemsData = agendaItems.map((i) => {
      if (id === i._id) {
        if (e.target.name === "completed") {
          i[e.target.name] = e.target.checked;
        } else {
          i[e.target.name] = e.target.value;
        }
      }
      return i;
    });

    setAgendaItems(newAgendaItemsData);
  };

  const handleAddAgendaItem = () => {
    setAgendaItems([
      ...agendaItems,
      {
        _id: uuidv4(),
        description: "",
        description_english: "",
        completed: false,
      },
    ]);
  };

  const handleRemoveAgendaItem = (id) => {
    let newAgendaItems = agendaItems.filter((i) => i._id !== id);
    setAgendaItems(newAgendaItems);
  };

  const handleChangeInputAction = (e, id) => {
    const newActionItemsData = actionItems.map((i) => {
      if (id === i._id) {
        if (e.target.name === "completed") {
          i[e.target.name] = e.target.checked;
        } else {
          i[e.target.name] = e.target.value;
        }
      }
      return i;
    });

    setActionItems(newActionItemsData);
  };

  const handleChangeDueDateActionItem = (date, id) => {
    const newActionItemsData = actionItems.map((i) => {
      if (id === i._id) {
        i.dueDate = date;
      }
      return i;
    });

    setActionItems(newActionItemsData);
  };

  const handleChangeResponsibleActionItem = (e, id) => {
    const newActionItemsData = actionItems.map((i) => {
      if (id === i._id) {
        let responsibleName = users.find(
          (user) => user.id === e.target.value
        ).name;
        i.responsible = e.target.value;
        i.responsibleName = responsibleName;
      }
      return i;
    });

    setActionItems(newActionItemsData);
  };

  const handleAddActionItem = () => {
    let defaultUser = users[0];

    setActionItems([
      ...actionItems,
      {
        _id: uuidv4(),
        description: "",
        description_english: "",
        completed: false,
        dueDate: new Date(),
        responsible: defaultUser.id,
        responsibleName: defaultUser.name,
      },
    ]);
  };

  const handleRemoveActionItem = (id) => {
    let newActionItems = actionItems.filter((i) => i._id !== id);
    setActionItems(newActionItems);
  };

  const updateMeetingDetails = async () => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/meetings/details/${mid}`,
      data: {
        agendaItems: agendaItems,
        actionItems: actionItems,
        status: "open",
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate("/mymeetings");
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const closeMeeting = async () => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/meetings/close/meeting/${mid}`,
      data: {
        agendaItems: agendaItems,
        actionItems: actionItems,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate("/mymeetings");
  };

  const buttonSelector = (status, creator, user) => {
    if (status === "closed" && creator === state.userId) {
      return (
        <Button variant="secondary" onClick={() => navigate("/mymeetings")}>
          {t("Reuniones.Detalle.Regresar")}
        </Button>
      );
    } else if (status === "open" && creator === state.userId) {
      return (
        <>
          <Button
            variant="primary"
            className="primary"
            onClick={() => updateMeetingDetails()}
          >
            {t("Reuniones.Detalle.Guardar")}
          </Button>
          <Button variant="danger" onClick={() => closeMeeting()}>
            {t("Reuniones.Detalle.Cerrar")}
          </Button>
        </>
      );
    } else {
      return (
        <Button
          variant="secondary"
          onClick={() => {
            if (state.role === "root" || state.role === "admin") {
              navigate("/meetings");
            } else {
              navigate("/mymeetings");
            }
          }}
        >
          {t("Reuniones.Detalle.Regresar")}
        </Button>
      );
    }
  };

  const addComment = async () => {
    setIsLoading(true);
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/meetings/comment/update/${mid}`,
      data: {
        comment: comment,
        userId: state.userId,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/meetings/${mid}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    setMeeting(result.data.meeting);
    setComments(result.data.meeting.comments);
    setAgendaItems(result.data.meeting.agendaItems);
    if (result.data.meeting.actionItems.length > 0) {
      setActionItems(result.data.meeting.actionItems);
    } else {
      setActionItems([
        {
          _id: uuidv4(),
          description: "",
          completed: false,
          dueDate: new Date(),
        },
      ]);
    }
    setIsLoading(false);
  };

  if (isLoading === true) {
    return <Spinner animation="border" role="status" />;
  } else {
    return (
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col lg={9} className="mb-3">
            <h4>{meeting.title}</h4>
          </Col>
          <Col lg={1} className="mb-3 right">
            {meeting.status === "closed" ? (
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <FontAwesomeIcon icon={faList} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => navigate(`/meetings/details/pdf/${mid}`)}
                  >
                    <FontAwesomeIcon icon={faFilePdf} />{" "}
                    {t("Reportes.DescargarPDF")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col lg={10} className="meeting-details">
            <Col className="mb-3 meeting-details-agenda">
              <Row className="justify-content-center align-items-center">
                <Col lg={11}>
                  <h3 className="section">
                    {t("Reuniones.MiReunion.Reunion.Agenda")}
                  </h3>
                </Col>
              </Row>
            </Col>

            <Col className="mb-3 meeting-details-agenda-items">
              <Row className="align-items-center justify-content-center">
                <Col>
                  {agendaItems.map((element) => (
                    <NewAgendaItem
                      id={element._id}
                      key={element._id}
                      description={element.description}
                      handleChangeInputAgenda={handleChangeInputAgenda}
                      handleRemoveAgendaItem={handleRemoveAgendaItem}
                      completed={element.completed}
                      disabled={disabled}
                      status={meeting.status}
                    />
                  ))}
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center">
                <Col lg={10}>
                  <span
                    className="add-element"
                    onClick={() => handleAddAgendaItem()}
                  >
                    {t("Reuniones.MiReunion.Reunion.AgregarTema")}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col className="mb-3 meeting-details-agenda">
              <Row className="justify-content-center align-items-center">
                <Col lg={11}>
                  <h3 className="section">
                    {t("Reuniones.MiReunion.Reunion.Acciones")}
                  </h3>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row className="justify-content-center align-items-center">
                <Col lg={1} className="center">
                  <GiTransform />
                </Col>
                <Col lg={6}>
                  <span className="title">
                    {t("Reuniones.MiReunion.Reunion.Accion")}
                  </span>
                </Col>
                <Col lg={2}>
                  <span className="title">
                    {t("Reuniones.MiReunion.Reunion.Responsable")}
                  </span>
                </Col>
                <Col lg={3}>
                  <span className="title">
                    {t("Reuniones.MiReunion.Reunion.Fecha")}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col className=" mb-3 meeting-details-agenda-items">
              <Row className="align-items-center justify-content-center">
                <Col>
                  {actionItems.map((element) => (
                    <NewActionItem
                      id={element._id}
                      key={element._id}
                      description={element.description}
                      handleChangeInputAction={handleChangeInputAction}
                      handleChangeDueDateActionItem={
                        handleChangeDueDateActionItem
                      }
                      handleRemoveActionItem={handleRemoveActionItem}
                      dueDate={new Date(element.dueDate)}
                      completed={element.completed}
                      disabled={disabled}
                      status={meeting.status}
                      users={users}
                      handleChangeResponsibleActionItem={
                        handleChangeResponsibleActionItem
                      }
                      responsible={element.responsible}
                    />
                  ))}
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center">
                <Col lg={10}>
                  <span
                    className="add-element"
                    onClick={() => handleAddActionItem()}
                  >
                    {t("Reuniones.MiReunion.Reunion.AgregarAccion")}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col className="mb-3 meeting-details-agenda">
              <Row className="justify-content-center align-items-center">
                <Col lg={11}>
                  <h3 className="section">
                    {t("Reuniones.MiReunion.Reunion.Notas")}
                  </h3>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row className="justify-content-center align-items-center">
                <Col lg={10} className="mb-3">
                  {comments.map((comment) => (
                    <Col className="comment-container" key={uuidv4()}>
                      <Row className="justify-content-center align-items-center">
                        <Col lg={3}>
                          <p className="comment">{comment.userName}</p>
                        </Col>
                        <Col lg={9}>
                          <Badge bg="light" text="dark">
                            {comment.message}
                          </Badge>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center">
                <Col lg={10} className="mb-3">
                  <Form.Control
                    as="textarea"
                    name="comment"
                    placeholder="Agregar un comentario..."
                    rows={3}
                    onChange={handleCommentChange}
                    disabled={
                      meeting.status === "closed" ||
                      (state.userId !== meeting.user &&
                        state.userId !== meeting.creator)
                    }
                  />
                </Col>
                <Col lg={1}>
                  <Button
                    variant="primary"
                    className="primary"
                    onClick={() => addComment()}
                    disabled={
                      meeting.status === "closed" ||
                      (state.userId !== meeting.user &&
                        state.userId !== meeting.creator)
                    }
                  >
                    <RiSendPlaneFill className="send-icon" />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="justify-content-center align-items-center">
                <Col>
                  {buttonSelector(
                    meeting.status,
                    meeting.creator,
                    meeting.user
                  )}
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MeetingDetails;
